<template>
  <v-card title="Sellers" class="mb-6">
    <v-card-subtitle>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="filter.last_name"
            clearable
            label="Last name"
            prepend-icon="mdi-store"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="filter.name"
            clearable
            label="Name"
            prepend-icon="mdi-account"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="filter.email"
            clearable
            label="Email"
            prepend-icon="mdi-mail"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center align-cente py-4">
            <v-pagination
              v-model="filter.page"
              :length="totalPages"
              :total-visible="7"
              size="x-small"
              rounded="0"
              style="display: inline-block"
            ></v-pagination>
            <v-select
              v-model="filter.page_size"
              :items="$options.pageCounts"
              density="compact"
              color="light-blue"
              hide-details
              style="display: inline-block; max-width: 70px"
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-table v-if="sellers.length || is_loading" style="padding: 20px 0">
      <thead>
        <tr>
          <th>Name</th>
          <th class="text-center">Ban status</th>
          <th></th>
        </tr>
        <tr v-for="item in sellers" :key="item.id">
          <td>
            {{ `${getUserName(item)}` }}
          </td>
          <td class="text-center">
            <v-icon
              v-if="item.admin_ban"
              icon="mdi-close-circle"
              size="large"
              color="red"
            ></v-icon>
            <!-- <span v-else>-</span> -->
          </td>
          <td>
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn color="indigo" style="margin: 5px 0" v-bind="props">
                  <v-icon
                    icon="mdi-dots-vertical"
                    size="large"
                  ></v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="goToSellerOrders(item.email)">
                  <v-list-item-title>Go to orders</v-list-item-title>
                </v-list-item>
                <v-list-item @click="goToSellerShops(item.email)">
                  <v-list-item-title>Go to Shops</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="!item.admin_ban" @click="openBan(item.id)">
                  <v-list-item-title>Ban</v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="openBan(item.id)">
                  <v-list-item-title>Unban</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openBanHistoryModal(item.id)">
                  <v-list-item-title>Ban history </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- <v-btn
              color="primary"
              small
              style="font-size: 10px; padding: 7px; width: 100%; margin-top: 10px;"
              @click="goToSellerOrders(item.email)"
            >
              Go to orders
            </v-btn>
            <v-btn
              color="primary"
              small
              style="font-size: 10px; padding: 7px; width: 100%; margin-top: 10px;"
              @click="goToSellerShops(item.email)"
            >
              Go to Shops
            </v-btn>
            <v-btn
              v-if="!item.admin_ban"
              color="red"
              small
              style="
                font-size: 10px;
                padding: 7px;
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
              "
              @click="openBan(item.id)"
            >
              Ban
            </v-btn>
            <v-btn
              v-else
              color="green"
              small
              style="
                font-size: 10px;
                padding: 7px;
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
              "
              @click="openBan(item.id)"
            >
              Unban
            </v-btn>
            <v-btn
              color="indigo"
              small
              style="
                font-size: 10px;
                padding: 7px;
                width: 100%;
                margin: 0 0 10px 0;
              "
              @click="openBanHistoryModal(item.id)"
            >
              Ban history
            </v-btn> -->
          </td>
        </tr>
      </thead>
    </v-table>
    <div v-else class="text-center">No entries to show</div>
    <div class="d-flex justify-center align-cente py-4">
      <v-pagination
        v-model="filter.page"
        :length="totalPages"
        :total-visible="7"
        size="x-small"
        rounded="0"
        style="display: inline-block"
      ></v-pagination>
      <v-select
        v-model="filter.page_size"
        :items="$options.pageCounts"
        density="compact"
        color="light-blue"
        hide-details
        style="display: inline-block; max-width: 70px"
      ></v-select>
    </div>
    <BanModal :open="showBanPopUp" @close="closeBan" @send="banSeller" />
    <HistoryModal
      :open="showHistoryPopUp"
      :history="history"
      @close="closeBanHistoryModal"
    />
  </v-card>
</template>

<script>
import BanModal from "../components/BanModal.vue";
import HistoryModal from "../components/HistoryModal.vue";
import {
  getSellers,
  getSellerBanHistory,
  banSeller,
} from "../services/sellers";
import { debounce } from "lodash";
import errorHandler from "../helpers/errorHandler"

export default {
  pageCounts: [10, 20, 50, 80, 100],
  name: "SellersPage",
  components: { BanModal, HistoryModal },
  props: {
    is_loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const query = {
      ...this.$route.query,
      page: +this.$route.query.page || 1,
      page_size: +this.$route.query.page_size || 10,
    };
    return {
      sellers: [],
      filter: { ...query },
      oldFilter: { ...query },
      totalPages: 1,
      debounce_fun: null,
      bannedSellerId: null,
      showBanPopUp: false,
      showHistoryPopUp: false,
      history: [],
    };
  },

  created() {
    this.debounce_fun = debounce(() => {
      this.getSellers();
    }, 1500);
    this.getSellers();
  },

  watch: {
    filter: {
      deep: true,
      async handler(newFilter) {
        const fields = ["last_name", "name", "email"];
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          if (newFilter[field] !== this.oldFilter[field]) {
            this.oldFilter = { ...newFilter };
            if (newFilter[field] === null) {
              this.filter[field] = "";
            }
            this.debounce_fun();
            return;
          }
        }

        this.oldFilter = { ...newFilter };
        await this.getSellers();
      },
    },
  },

  methods: {
    goToSellerOrders(seller_email) {
      this.$router.push({
        name: "orders",
        query: {
          seller_email
        }
      })
    },
    goToSellerShops(seller_email) {
      this.$router.push({
        name: "shops",
        query: {
          seller_email
        }
      })
    },
    closeBanHistoryModal() {
      this.showHistoryPopUp = false;
      this.history = [];
    },
    async openBanHistoryModal(seller_id) {
      await this.getBanHistory(seller_id);
      this.showHistoryPopUp = true;
    },
    async getBanHistory(seller_id) {
      try {
        this.$emit("set_loading", true);
        this.history = await getSellerBanHistory(seller_id);
      } catch (e) {
        const errorText = errorHandler(e, "Unrecognized error getting ban history");
        this.$notify({ text: errorText, group: "error" });
        throw e;
      } finally {
        this.$emit("set_loading", false);
      }
    },
    async banSeller(comment) {
      try {
        this.$emit("set_loading", true);
        const seller = this.sellers.find(
          (seller) => seller.id === this.bannedSellerId
        );
        await banSeller(this.bannedSellerId, comment, !seller.admin_ban);
        this.closeBan();
        seller.admin_ban = !seller.admin_ban;
      } catch (e) {
        const errorText = errorHandler(e, "Unidentified store ban error");
        this.$notify({ text: errorText, group: "error" });
      } finally {
        this.$emit("set_loading", false);
      }
    },
    openBan(seller_id) {
      this.bannedSellerId = seller_id;
      this.showBanPopUp = true;
    },
    closeBan() {
      this.bannedSellerId = null;
      this.showBanPopUp = false;
    },
    async getSellers() {
      try {
        this.$emit("set_loading", true);
        const {
          users,
          total,
          page_size = this.filter.page_size,
        } = await getSellers(this.filter);
        this.sellers = users;
        this.totalPages = Math.ceil(total / page_size);
        this.$router.push({ name: "sellers", query: this.filter });
      } catch (e) {
        const errorText = errorHandler(e, "Unrecognized error getting list of sellers");
        this.$notify({ text: errorText, group: "error" });
      } finally {
        this.$emit("set_loading", false);
      }
    },
    getUserName(user) {
      let result = "";
      if (user.name && user.last_name) {
        result = `${user.name} ${user.last_name}`;
      } else {
        result = "-";
      }
      return `${result} (${user.email})`;
    },
  },
};
</script>
