import axios from 'axios'

export const login = async (username, password) => {
    const { data } = await axios.post('/auth/login', {
        username, password
    })
    return data;
}

export const logout = async () => {
    const { data } = await axios.post('/auth/logout');
    return data;
}
