<template>
  <v-dialog :model-value="open" persistent>
    <v-card>
      <v-card-title class="text-h5"> Ban </v-card-title>
      <v-card-item>
        <v-textarea label="Comment" v-model="comment"></v-textarea>
      </v-card-item>
      <v-card-actions>
        <v-btn
          color="green-darken-1"
          variant="text"
          :disabled="!comment"
          @click="send"
        >
          Send
        </v-btn>
        <v-btn
          color="red-darken-1"
          variant="text"
          @click="close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    props: {
        open: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            comment: ''
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        send() {
            this.$emit('send', this.comment);
        }
    }
}
</script>
