import axios from 'axios'

export const getSellers = async (params = {}) => {
    const query = new URLSearchParams(params).toString();
    const { data } = await axios.get(`/users?${query}&role_code=seller`);
    return data;
}

export const banSeller = async (user_id, comment, active) => {
    const { data } = await axios.patch(`/users/${user_id}/ban`, { comment, active });
    return data;
}

export const getSellerBanHistory = async (user_id) => {
    const { data } = await axios.get(`/users/${user_id}/ban-history`);
    return data;
} 
