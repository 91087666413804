<template>
  <v-card title="Rename Shop Requests" class="mb-6">
    <v-card-subtitle>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="filter.shop_name"
            clearable
            label="New shop name"
            prepend-icon="mdi-store"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="filter.status"
            :items="$options.requestStatuses"
            clearable
            prepend-icon="mdi-list-status"
            density="compact"
            color="light-blue"
            hide-details
          />
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center align-cente py-4">
            <v-pagination
              v-model="filter.page"
              :length="totalPages"
              :total-visible="7"
              size="x-small"
              rounded="0"
              style="display: inline-block"
            ></v-pagination>
            <v-select
              v-model="filter.page_size"
              :items="$options.pageCounts"
              density="compact"
              color="light-blue"
              hide-details
              style="display: inline-block; max-width: 70px"
            ></v-select>
          </div>
        </v-col>
      </v-row> -->
    </v-card-subtitle>
    <v-table v-if="requests.length || is_loading" style="padding: 20px 0">
      <tr>
        <th class="text-center">Customer comment</th>
        <th class="text-center">Operator comment</th>
        <th class="text-center">New shop name</th>
        <th class="text-center">Status</th>
        <th class="text-center">Created at</th>
        <th></th>
      </tr>
      <tr v-for="item in requests" :key="item.id">
        <td class="text-center">{{ item.customer_comment }}</td>
        <td class="text-center">{{ item.operator_comment || "-" }}</td>
        <td class="text-center">{{ item.new_shop_name }}</td>
        <td class="text-center">{{ item.status }}</td>
        <td class="text-center">
          {{ item.created_at?.replaceAll("T", " ").substring(0, 19) || "-" }}
        </td>
        <td>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn color="indigo" style="margin: 5px 0" v-bind="props">
                <v-icon icon="mdi-dots-vertical" size="large"></v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item style="cursor: pointer" @click="openRenameModal(item.id)">
                <v-list-item-title>Change request status</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </td>
      </tr>
    </v-table>
    <!-- <div class="d-flex justify-center align-cente py-4">
      <v-pagination
        v-model="filter.page"
        :length="totalPages"
        :total-visible="7"
        size="x-small"
        rounded="0"
        style="display: inline-block"
      ></v-pagination>
      <v-select
        v-model="filter.page_size"
        :items="$options.pageCounts"
        density="compact"
        color="light-blue"
        hide-details
        style="display: inline-block; max-width: 70px"
      ></v-select>
    </div> -->
    <RenameShopModal
      :open="showRenamePopUp"
      @close="closeRenameShopModal"
      @send="changeRenameStatus"
    />
  </v-card>
</template>

<script>
import { debounce } from "lodash";
import { getRequests, changeRenameStatus } from "../services/rename_requests";
import errorHandler from "../helpers/errorHandler";
import RenameShopModal from "../components/RenameShopModal"

export default {
  name: "RenameRequestsPage",
  requestStatuses: ["pending", "accepted", "declined"],
  pageCounts: [10, 20, 50, 80, 100],
  components: { RenameShopModal },
  props: {
    is_loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const query = {
      ...this.$route.query,
      // page: +this.$route.query.page || 1,
      // page_size: +this.$route.query.page_size || 10,
    };
    return {
      requests: [],
      filter: { ...query },
      oldFilter: { ...query },
      totalPages: 1,
      debounce_fun: null,
      showRenamePopUp: false,
      requestId: null,
    };
  },
  async created() {
    this.debounce_fun = debounce(() => {
      this.getRequests();
    }, 1500);
    this.getRequests();
  },
  watch: {
    filter: {
      deep: true,
      async handler(newFilter) {
        const fields = ["shop_name"];
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          if (newFilter[field] !== this.oldFilter[field]) {
            this.oldFilter = { ...newFilter };
            if (newFilter[field] === null) {
              this.filter[field] = "";
            }
            this.debounce_fun();
            return;
          }
        }

        this.oldFilter = { ...newFilter };
        this.getRequests();
      },
    },
  },
  methods: {
    closeRenameShopModal() {
      this.showRenamePopUp = false;
      this.requestId = null;
    },
    async changeRenameStatus({ status, comment }) {
      try {
        this.$emit("set_loading", true);
        await changeRenameStatus(status, this.requestId, comment);
        this.closeRenameShopModal();
        await this.getRequests();
      } catch (e) {
        const errorText = errorHandler(e, "Unrecognized error rename");
        this.$notify({ text: errorText, group: "error" });
        throw e;
      } finally {
        this.$emit("set_loading", false);
      }
    },
    openRenameModal(id) {
      this.requestId = id;
      this.showRenamePopUp = true;
    },
    async getRequests() {
      try {
        this.$emit("set_loading", true);
        const fields = ["shop_name", "status"];

        const request = { ...this.filter };

        fields.forEach((field) => {
          if (!request[field]) {
            delete request[field];
          }
        });
        const data = await getRequests(request);
        this.requests = data;
        this.$router.push({ name: "rename_requests", query: this.filter });
      } catch (e) {
        const errorText = errorHandler(
          e,
          "Unrecognized error getting remane reuests list"
        );
        this.$notify({ text: errorText, group: "error" });
      } finally {
        this.$emit("set_loading", false);
      }
    },
  },
};
</script>
