<template>
  <v-card title="Products" class="mb-6">
    <v-card-subtitle style="overflow: visible">
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="filter.search_query"
            clearable
            label="Search"
            prepend-icon="mdi-search-web"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="filter.shop_name"
            clearable
            label="Shop name"
            prepend-icon="mdi-store"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="filter.seller_email"
            clearable
            label="Owner email"
            prepend-icon="mdi-mail"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <VueDatePicker
            v-model="date"
            ref="datepicker"
            range
            :format="format"
            @cleared="clearDates"
          ></VueDatePicker>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="dataSelectValue"
            :items="$options.rangeSelect"
            clearable
            prepend-icon="mdi-calendar-range"
            density="compact"
            color="light-blue"
            hide-details
            @click:clear="clearDates"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <div class="d-flex justify-center align-cente py-4">
      <v-pagination
        v-model="filter.page"
        :length="totalPages"
        :total-visible="7"
        size="x-small"
        rounded="0"
        style="display: inline-block"
      ></v-pagination>
      <v-select
        v-model="filter.page_size"
        :items="$options.pageCounts"
        density="compact"
        color="light-blue"
        hide-details
        style="display: inline-block; max-width: 70px"
      ></v-select>
    </div>
    <v-table v-if="products.length || is_loading" style="padding: 20px 0">
      <thead>
        <tr>
          <th class="text-center">Preview</th>
          <th class="text-left">Product title</th>
          <th class="text-center">Active status</th>
          <th class="text-center">Ban status</th>
          <th class="text-left">Category</th>
          <th class="text-center">Price</th>
          <th class="text-center">Offer Price</th>
          <th class="text-center">Type</th>
          <th class="text-center">Tags</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in products" :key="item.id">
          <td class="text-center">
            <v-img
              v-if="item.preview_image?.url[200]"
              style="margin: 0 auto"
              width="100"
              height="100"
              :aspect-ratio="1"
              :src="item.preview_image?.url[200]"
            ></v-img>
            <v-img
              v-else
              style="margin: 0 auto"
              width="100"
              height="100"
              :aspect-ratio="1"
              src="https://via.placeholder.com/200"
            ></v-img>
          </td>
          <td style="max-width: 300px">
            <div>
              Shop:
              <a href @click.prevent="filter.shop_name = item.shop.name">{{
                item.shop.name
              }}</a>
            </div>
            <div>
              Owner:
              <a
                v-if="item.shop.user?.email"
                href
                @click.prevent="filter.seller_email = item.shop.user.email"
                >{{ item.shop.user?.email }}</a
              >
              <span v-else>-</span>
            </div>
            <br />
            <a
              :href="`${client_url}/product/${item.id}/${makeSlug(item.title)}`"
              target="_blank"
              >{{ item.title }}</a
            >
          </td>
          <td class="text-center">
            <v-icon
              v-if="item.active"
              icon="mdi-checkbox-marked-circle"
              size="large"
              color="green"
            ></v-icon>
            <v-icon
              v-else
              icon="mdi-close-circle"
              size="large"
              color="red"
            ></v-icon>
          </td>
          <td class="text-center">
            <v-icon
              v-if="item.admin_ban"
              icon="mdi-close-circle"
              size="large"
              color="red"
            ></v-icon>
            <!-- <span v-else>-</span> -->
          </td>
          <td>
            {{ getCategoryPath(item) }}
          </td>
          <td class="text-center">${{ item.price.toFixed(2) }}</td>
          <td class="text-center">
            <span v-if="item.offer_price !== item.price">
              ${{ item.offer_price.toFixed(2) }}
            </span>
            <span v-else> - </span>
          </td>
          <td class="text-center">
            <span v-if="item.is_digital"> Digital </span>
            <span v-else> Physical </span>
          </td>
          <td style="max-width: 300px">
            <v-chip
              v-for="tag in item.tags"
              :key="tag.id"
              class="ma-2"
              @click:close="removeTag"
            >
              {{ tag.code }}
            </v-chip>
          </td>
          <td>
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn color="indigo" style="margin: 5px 0" v-bind="props">
                  <v-icon icon="mdi-dots-vertical" size="large"></v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-if="item.video_url"
                  @click="setVideoUrl(item.video_url)"
                >
                  <v-list-item-title>Show product video</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="!item.admin_ban" @click="openBan(item.id)">
                  <v-list-item-title>Ban</v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="openBan(item.id)">
                  <v-list-item-title>Unban</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openBanHistoryModal(item.id)">
                  <v-list-item-title>Ban history </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- <v-btn
              v-if="item.video_url"
              color="primary"
              style="
                font-size: 10px;
                padding: 7px;
                width: 100%;
                margin: 0 0 10px 0;
              "
              @click="setVideoUrl(item.video_url)"
            >
              <v-icon icon="mdi-eye" color="#fff"></v-icon>
              <v-tooltip activator="parent" location="top"
                >Show product video</v-tooltip
              >
            </v-btn>
            <v-btn
              v-if="!item.admin_ban"
              color="red"
              small
              style="
                font-size: 10px;
                padding: 7px;
                width: 100%;
                margin: 0 0 10px 0;
              "
              @click="openBan(item.id)"
            >
              Ban
            </v-btn>
            <v-btn
              v-else
              color="green"
              small
              style="
                font-size: 10px;
                padding: 7px;
                width: 100%;
                margin: 0 0 10px 0;
              "
              @click="openBan(item.id)"
            >
              Unban
            </v-btn>
            <v-btn
              color="indigo"
              small
              style="
                font-size: 10px;
                padding: 7px;
                width: 100%;
                margin: 0 0 10px 0;
              "
              @click="openBanHistoryModal(item.id)"
            >
              Ban history
            </v-btn> -->
          </td>
        </tr>
      </tbody>
    </v-table>
    <div v-else class="text-center">No entries to show</div>
    <div class="d-flex justify-center align-cente py-4">
      <v-pagination
        v-model="filter.page"
        :length="totalPages"
        :total-visible="7"
        size="x-small"
        rounded="0"
        style="display: inline-block"
      ></v-pagination>
      <v-select
        v-model="filter.page_size"
        :items="$options.pageCounts"
        density="compact"
        color="light-blue"
        hide-details
        style="display: inline-block; max-width: 70px"
      ></v-select>
    </div>
    <v-dialog v-model="showVideoDialog">
      <v-card>
        <v-card-title class="text-h5"> Video </v-card-title>
        <v-card-item>
          <video :src="videoUrl"></video>
        </v-card-item>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="showVideoDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <BanModal :open="showBanPopUp" @close="closeBan" @send="banProduct" />
    <HistoryModal
      :open="showHistoryPopUp"
      :history="history"
      @close="closeBanHistoryModal"
    />
  </v-card>
</template>

<script>
import { debounce } from "lodash";
import {
  getProducts,
  banProduct,
  getProductBanHistory,
} from "../services/products";
import BanModal from "../components/BanModal.vue";
import HistoryModal from "../components/HistoryModal.vue";
import errorHandler from "../helpers/errorHandler"

export default {
  name: "ProductsPage",
  pageCounts: [10, 20, 50, 80, 100],
  rangeSelect: ["today", "7 days", "30 days"],
  components: { BanModal, HistoryModal },
  props: {
    is_loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const query = {
      ...this.$route.query,
      shop_name: this.$route.query.shop_name || "",
      search_query: this.$route.query.search_query || "",
      page: +this.$route.query.page || 1,
      page_size: +this.$route.query.page_size || 10,
      sort_order: this.$route.query.sort_order || "asc",
      sort_field: this.$route.query.sort_field || "price",
    };
    const { updated_start_date, updated_end_date } = this.$route.query;
    const date =
      updated_start_date && updated_end_date
        ? [updated_start_date, updated_end_date]
        : [];
    return {
      products: [],
      filter: { ...query },
      oldFilter: { ...query },
      date,
      dataSelectValue: null,
      total: 0,
      showVideoDialog: false,
      videoUrl: "",
      client_url: process.env.VUE_APP_CLIENT_URL,
      debounce_fun: null,
      totalPages: 1,
      showBanPopUp: false,
      showHistoryPopUp: false,
      bannedProductId: null,
      history: [],
    };
  },

  created() {
    this.debounce_fun = debounce(() => {
      this.getProducts();
    }, 1500);
    this.getDataSelectValue();
    this.getProducts();
  },

  watch: {
    dataSelectValue() {
      const today = new Date();
      if (this.dataSelectValue === "today") {
        const start_date = today.toISOString().slice(0, -14);
        const end_date = today.toISOString().slice(0, -14);
        this.date = [start_date, end_date];
      }
      if (this.dataSelectValue === "7 days") {
        const end_date = today.toISOString().slice(0, -14);
        today.setDate(today.getDate() - 7);
        const start_date = today.toISOString().slice(0, -14);
        this.date = [start_date, end_date];
      }
      if (this.dataSelectValue === "30 days") {
        const end_date = today.toISOString().slice(0, -14);
        today.setDate(today.getDate() - 30);
        const start_date = today.toISOString().slice(0, -14);
        this.date = [start_date, end_date];
      }
    },
    date() {
      this.getDataSelectValue();
    },
    filter: {
      deep: true,
      async handler(newFilter) {
        const fields = ["search_query", "shop_name", "seller_email"];
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          if (newFilter[field] !== this.oldFilter[field]) {
            this.oldFilter = { ...newFilter };
            if (newFilter[field] === null) {
              this.filter[field] = "";
            }
            this.debounce_fun();
            return;
          }
        }

        this.oldFilter = { ...newFilter };
        this.getProducts();
      },
    },
  },

  methods: {
    getDataSelectValue() {
      if (!this.date?.length) {
        delete this.filter.updated_start_date;
        delete this.filter.updated_end_date;
        this.dataSelectValue = null;
        return;
      }
      this.filter.updated_start_date = new Date(this.date[0])
        .toISOString()
        .slice(0, -14);
      this.filter.updated_end_date = new Date(this.date[1]).toISOString().slice(0, -14);
      const date1 = new Date(this.date[0]).toISOString().substring(0, 10);
      const date2 = new Date(this.date[1]).toISOString().substring(0, 10);
      const dateToday = new Date().toISOString().substring(0, 10);
      if (date2 !== dateToday) {
        this.dataSelectValue = "arbitrary date";
        return;
      }
      if (date1 === date2) {
        this.dataSelectValue = "today";
        return;
      }
      const date7FromToday = new Date();
      date7FromToday.setDate(date7FromToday.getDate() - 7);
      const date7FromTodayStr = date7FromToday.toISOString().substring(0, 10);

      if (date1 === date7FromTodayStr) {
        this.dataSelectValue = "7 days";
        return;
      }

      const date30FromToday = new Date();
      date30FromToday.setDate(date30FromToday.getDate() - 30);
      const date30FromTodayStr = date30FromToday.toISOString().substring(0, 10);

      if (date1 === date30FromTodayStr) {
        this.dataSelectValue = "30 days";
        return;
      }

      this.dataSelectValue = "arbitrary date";
    },
    clearDates() {
      this.date = [];
    },
    format(dates) {
      const start_date = new Date(dates[0])?.toISOString().slice(0, -14) || "";
      const end_date = new Date(dates[1])?.toISOString().slice(0, -14) || "";
      return `${start_date} - ${end_date}`;
    },
    closeBanHistoryModal() {
      this.showHistoryPopUp = false;
      this.history = [];
    },
    async openBanHistoryModal(product_id) {
      await this.getBanHistory(product_id);
      this.showHistoryPopUp = true;
    },
    async getBanHistory(product_id) {
      try {
        this.$emit("set_loading", true);
        this.history = await getProductBanHistory(product_id);
      } catch (e) {
        const errorText = errorHandler(e, "Unrecognized error getting ban history");
        this.$notify({ text: errorText, group: "error" });
        throw e;
      } finally {
        this.$emit("set_loading", false);
      }
    },
    openBan(product_id) {
      this.bannedProductId = product_id;
      this.showBanPopUp = true;
    },
    closeBan() {
      this.bannedProductId = null;
      this.showBanPopUp = false;
    },
    async banProduct(comment) {
      try {
        this.$emit("set_loading", true);
        const product = this.products.find(
          (product) => product.id === this.bannedProductId
        );
        await banProduct(this.bannedProductId, comment, !product.admin_ban);
        this.closeBan();
        product.admin_ban = !product.admin_ban;
      } catch (e) {
        const errorText = errorHandler(e, "Unidentified product ban error");
        this.$notify({ text: errorText, group: "error" });
      } finally {
        this.$emit("set_loading", false);
      }
    },
    getCategoryPath({ category }) {
      const { parents = [] } = category || {};
      const srtedParents = [...parents].sort(
        (a, b) => a.parents.length - b.parents.length
      );
      const parentPath = srtedParents.reduce(
        (acc, { name }) => `${acc}${name} / `,
        ""
      );
      return category ? `${parentPath}${category.name}` : "-";
    },
    async getProducts() {
      try {
        this.$emit("set_loading", true);
        const fields = ["seller_email"];

        const request = { ...this.filter };

        fields.forEach((field) => {
          if (!request[field]) {
            delete request[field];
          }
        });
        const { products, total, page_size } = await getProducts(request);
        this.products = products;
        this.totalPages = Math.ceil(total / page_size);
        this.$router.push({ name: "products", query: this.filter });
      } catch (e) {
        const errorText = errorHandler(e, "Unrecognized error getting product list");
        this.$notify({ text: errorText, group: "error" });
      } finally {
        this.$emit("set_loading", false);
      }
    },
    setVideoUrl(url) {
      this.showVideoDialog = true;
      this.videoUrl = url;
    },
    makeSlug(title) {
      let url = title.replace(/^\s+|\s+$/g, "").toLowerCase();

      // remove accents, swap ñ for n, etc
      const from =
        "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
      const to =
        "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";

      for (var i = 0, l = from.length; i < l; i++) {
        url = url.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      url = url
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
.description_wrapper {
  width: 300px;
  padding: 2px 5px;
  white-space: initial;
  &_hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
</style>
