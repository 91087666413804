export default (e, default_text) => {
    if (typeof e?.response?.data === 'string') {
        return e?.response?.data;
    }
    if (e?.response?.data?.errors?.length) {
        return e.response.data.errors[0];
    }
    if (e?.response?.data?.errors?.query) {
        return Object.entries(e.response.data.errors.query).reduce((acc, [key, value]) => `${acc} ${key}: ${value}`, '');
    }

    if (e?.response?.data?.errors?.json) {
        return Object.entries(e.response.data.errors.json).reduce((acc, [key, value]) => `${acc} ${key}: ${value}`, '');
    }

    if (e?.response?.data?.msg) {
        return e?.response?.data?.msg;
    }

    return (e && e?.toString()) || default_text;
}
