import axios from 'axios'

export const getShops = async (params) => {
    const query = new URLSearchParams(params).toString();
    const { data } = await axios.get(`/shops/?${query}`);
    return data;
}

export const banShop = async (shop_id, comment, active) => {
    const { data } = await axios.patch(`/shops/${shop_id}/ban`, { comment, active });
    return data;
}

export const getShopBanHistory = async (shop_id) => {
    const { data } = await axios.get(`/shops/${shop_id}/ban-history`);
    return data;
} 
