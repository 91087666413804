<template>
  <v-dialog :model-value="open" persistent>
    <v-card>
      <v-card-title class="text-h5"> Ban </v-card-title>
      <v-card-item>
        <v-list lines="one">
          <v-list-item
            v-for="item in history"
            :key="item.id"
            :title="`Status ${item.active ? 'banned' : 'unbanned'}: ${item.comment}`"
            :subtitle="getDateFormat(item)"
          ></v-list-item>
        </v-list>
      </v-card-item>
      <v-card-actions>
        <v-btn color="red-darken-1" variant="text" @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    history: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getDateFormat(item) {
      return item.created_at.replace('T', ' ')
    }
  },
};
</script>
