<template>
  <v-card class="mb-6">
    <div class="v-card-item">
      <div class="v-card-item__content">
        <div class="v-card-title">
          <span v-if="!filter.client_id">Orders</span>
          <span v-else>
            <a href @click.prevent="filter = { page: 1, page_size: 10 }"
              >Orders</a
            >
            > Orders of {{ client_name }}
          </span>
        </div>
      </div>
    </div>
    <v-card-subtitle style="overflow: visible">
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="filter.seller_email"
            clearable
            label="Owner email"
            prepend-icon="mdi-mail"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="filter.shop_name"
            clearable
            label="Shop name"
            prepend-icon="mdi-store"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="filter.order_status_id"
            :items="statuses"
            item-title="name"
            item-value="id"
            label="Statuses"
            clearable
            prepend-icon="mdi-list-status"
            density="compact"
            color="light-blue"
            hide-details
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="filter.buyer_email"
            clearable
            label="Buyer email"
            prepend-icon="mdi-mail"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <VueDatePicker
            v-model="date"
            ref="datepicker"
            range
            :format="format"
            @cleared="clearDates"
          ></VueDatePicker>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="dataSelectValue"
            :items="$options.rangeSelect"
            clearable
            prepend-icon="mdi-calendar-range"
            density="compact"
            color="light-blue"
            hide-details
            @click:clear="clearDates"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center align-cente py-4">
            <v-pagination
              v-model="filter.page"
              :length="totalPages"
              :total-visible="7"
              size="x-small"
              rounded="0"
              style="display: inline-block"
            ></v-pagination>
            <v-select
              v-model="filter.page_size"
              :items="$options.pageCounts"
              density="compact"
              color="light-blue"
              hide-details
              style="display: inline-block; max-width: 70px"
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-table v-if="orders.length || is_loading" style="padding: 20px 0">
      <thead>
        <tr>
          <th class="text-center">Order number</th>
          <th class="text-center">Shop</th>
          <th class="text-center">Price</th>
          <th class="text-center">Country</th>
          <th class="text-center">Status</th>
          <th class="text-center">Has personalization</th>
          <th class="text-center">Payment provider</th>
          <th class="text-center">Created at</th>
          <th class="text-center">Deadline</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in orders" :key="item.id">
          <td class="text-center">
            {{ item.id }}
          </td>
          <td v-if="item.shop" class="text-center">
            <a href @click.prevent="filter.shop_name = item.shop.name">{{
              item.shop.name
            }}</a>
            (<a
              v-if="item.shop.user"
              href
              @click.prevent="filter.seller_email = item.shop.user.email"
              >{{ item.shop.user.email }}</a
            >
            <span v-else> - </span>)
          </td>
          <td v-else class="text-center">-</td>
          <td class="text-center">{{ item.total_cost }}$</td>
          <td class="text-center">
            {{ item.address?.country?.name }}
          </td>
          <td class="text-center">
            {{ item.order_status?.name || "" }}
          </td>
          <td class="text-center">
            {{ orderHasPersonaliztion(item) ? "Yes" : "No" }}
          </td>
          <td class="text-center">
            {{ getPaymentProvider(item) }}
          </td>
          <td class="text-center">
            {{ item.created_at?.substring(0, 10) || "-" }}
          </td>
          <td class="text-center">
            {{ item.deadline?.substring(0, 10) || "-" }}
          </td>
          <td>
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn color="indigo" style="margin: 5px 0" v-bind="props">
                  <v-icon icon="mdi-dots-vertical" size="large"></v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="setShowingDetailsOrder(item.id)">
                  <v-list-item-title>Details</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openDecline(item.id)">
                  <v-list-item-title>Cancel order</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-table>
    <div v-else class="text-center">
      <span v-if="filter.client_id"
        >The selected user has no orders.
        <a href @click.prevent="filter = { page: 1, page_size: 10 }"
          >Show all.</a
        ></span
      ><span v-else> No entries to show</span>
    </div>
    <div class="d-flex justify-center align-cente py-4">
      <v-pagination
        v-model="filter.page"
        :length="totalPages"
        :total-visible="7"
        size="x-small"
        rounded="0"
        style="display: inline-block"
      ></v-pagination>
      <v-select
        v-model="filter.page_size"
        :items="$options.pageCounts"
        density="compact"
        color="light-blue"
        hide-details
        style="display: inline-block; max-width: 70px"
      ></v-select>
    </div>
    <v-dialog v-model="showDetails">
      <v-card v-if="!!showingOrder">
        <v-card-title class="text-h5">
          Order #{{ showingOrder.id }}
        </v-card-title>
        <v-card-item>
          <div>
            <span style="font-weight: bold">Shipping cost:</span>
            {{ showingOrder?.order_shipping?.shipping_cost || 0 }}$
          </div>
          <div>
            <span style="font-weight: bold">User:</span>
            {{ showingOrder.user.full_name }}
          </div>

          <div>
            <span style="font-weight: bold">Payment type:</span>
            {{
              showingOrder.payments.length
                ? showingOrder.payments[0].payment_provider?.code || "-"
                : "-"
            }}
          </div>

          <div>
            <span style="font-weight: bold">Track numbers:</span>
            {{ getTrackNumber(showingOrder) }}
          </div>

          <h4>Order Products:</h4>
          <v-table style="padding: 20px 0">
            <tr>
              <th class="text-center">Preview</th>
              <th class="text-center">Product title</th>
              <th class="text-center">Price</th>
              <th class="text-center">Variation</th>
            </tr>
            <tr v-for="item in showingOrder.products" :key="item.id">
              <td class="text-center">
                <v-img
                  v-if="item.product.preview_image?.url[200]"
                  style="margin: 0 auto"
                  width="100"
                  height="100"
                  :aspect-ratio="1"
                  :src="item.product.preview_image?.url[200]"
                ></v-img>
                <v-img
                  v-else
                  style="margin: 0 auto"
                  width="100"
                  height="100"
                  :aspect-ratio="1"
                  src="https://via.placeholder.com/200"
                ></v-img>
              </td>
              <td style="max-width: 300px" class="text-center">
                <a
                  :href="`${client_url}/product/${item.product.id}/${makeSlug(
                    item.product_name
                  )}`"
                  target="_blank"
                  >{{ item.product_name }}</a
                >
              </td>
              <td class="text-center">${{ item.price.toFixed(2) }}</td>
              <td class="text-center">
                <span v-if="!item.variation?.feature_options?.length"> - </span>
                <span v-else>
                  <span
                    v-for="option in item.variation.feature_options"
                    :key="option.feature_id"
                  >
                    {{ option.feature_name }} : {{ option.option_value }}
                  </span>
                </span>
              </td>
            </tr>
          </v-table>
        </v-card-item>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="showDetails = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DeclineOrderModal
      :open="showDeclinePopUp"
      @close="closeDecline"
      @send="declineOrder"
    />
  </v-card>
</template>

<script>
import DeclineOrderModal from "../components/DeclineOrderModal.vue";
import errorHandler from "../helpers/errorHandler"

import {
  getOrders,
  getOrderById,
  getOrderStatuses,
  declineOrder,
} from "../services/orders";
import { debounce } from "lodash";

export default {
  name: "OrdersPage",
  pageCounts: [10, 20, 50, 80, 100],
  rangeSelect: ["today", "7 days", "30 days"],
  components: { DeclineOrderModal },
  props: {
    is_loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const query = {
      ...this.$route.query,
      order_status_id: +this.$route.query.order_status_id || null,
      page: +this.$route.query.page || 1,
      page_size: +this.$route.query.page_size || 10,
    };
    const { start_date, end_date } = this.$route.query;
    const date = start_date && end_date ? [start_date, end_date] : [];
    const client_name = query.client_id
      ? localStorage.getItem("client_name")
      : "";
    localStorage.removeItem("client_name");
    return {
      showDeclinePopUp: false,
      declinedOrderId: null,
      orders: [],
      statuses: [],
      showingOrder: null,
      showDetails: false,
      filter: {
        ...query,
      },
      oldFilter: {
        ...query,
      },
      date,
      dataSelectValue: null,
      debounce_fun: null,
      client_url: process.env.VUE_APP_CLIENT_URL,
      totalPages: 1,
      client_name,
    };
  },

  created() {
    this.getStatuses();
    this.getDataSelectValue();
    this.debounce_fun = debounce(() => {
      this.getOrders();
    }, 1500);
    this.getOrders();
  },

  watch: {
    dataSelectValue() {
      const today = new Date();
      if (this.dataSelectValue === "today") {
        const start_date = today.toISOString().slice(0, -14);
        const end_date = today.toISOString().slice(0, -14);
        this.date = [start_date, end_date];
      }
      if (this.dataSelectValue === "7 days") {
        const end_date = today.toISOString().slice(0, -14);
        today.setDate(today.getDate() - 7);
        const start_date = today.toISOString().slice(0, -14);
        this.date = [start_date, end_date];
      }
      if (this.dataSelectValue === "30 days") {
        const end_date = today.toISOString().slice(0, -14);
        today.setDate(today.getDate() - 30);
        const start_date = today.toISOString().slice(0, -14);
        this.date = [start_date, end_date];
      }
    },
    date() {
      this.getDataSelectValue();
    },
    filter: {
      deep: true,
      async handler(newFilter) {
        if (!this.filter.client_id) {
          this.client_name = "";
        }
        const fields = ["seller_email", "buyer_email", "shop_name"];
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          if (newFilter[field] !== this.oldFilter[field]) {
            this.oldFilter = { ...newFilter };
            if (newFilter[field] === null) {
              this.filter[field] = "";
            }
            this.debounce_fun();
            return;
          }
        }

        this.oldFilter = { ...newFilter };
        this.getOrders();
      },
    },
    showDetails() {
      if (!this.showDetails) {
        this.showingOrder = null;
      }
    },
  },

  methods: {
    getTrackNumber(order) {
      const { fulfillments } = order;
      return fulfillments.reduce((acc, fulfillment) => {
        const { track_number = null } = fulfillment;
        if(!track_number) {
          return ''
        }
        const { carrier, track } = track_number;
        return `${acc} ${carrier.name}: ${track}.`
      }, '') || '-'
    },
    async declineOrder(reason) {
      try {
        this.$emit("set_loading", true);
        await declineOrder(this.declinedOrderId, reason);
        await this.getOrders();
      } catch (e) {
        const errorText = errorHandler(e, "Unrecognized error decline order");
        this.$notify({ text: errorText, group: "error" });
      } finally {
        this.closeDecline();
        this.$emit("set_loading", false);
      }
    },
    openDecline(order_id) {
      this.showDeclinePopUp = true;
      this.declinedOrderId = order_id;
    },
    closeDecline() {
      this.showDeclinePopUp = false;
      this.declinedOrderId = null;
    },
    getPaymentProvider(item) {
      const { payments = [] } = item;
      return payments.length
        ? payments[0].payment_provider?.code || " - "
        : " - ";
    },
    getDataSelectValue() {
      if (!this.date?.length) {
        delete this.filter.start_date;
        delete this.filter.end_date;
        this.dataSelectValue = null;
        return;
      }
      this.filter.start_date = new Date(this.date[0])
        .toISOString()
        .slice(0, -14);
      this.filter.end_date = new Date(this.date[1]).toISOString().slice(0, -14);
      const date1 = new Date(this.date[0]).toISOString().substring(0, 10);
      const date2 = new Date(this.date[1]).toISOString().substring(0, 10);
      const dateToday = new Date().toISOString().substring(0, 10);
      if (date2 !== dateToday) {
        this.dataSelectValue = "arbitrary date";
        return;
      }
      if (date1 === date2) {
        this.dataSelectValue = "today";
        return;
      }
      const date7FromToday = new Date();
      date7FromToday.setDate(date7FromToday.getDate() - 7);
      const date7FromTodayStr = date7FromToday.toISOString().substring(0, 10);

      if (date1 === date7FromTodayStr) {
        this.dataSelectValue = "7 days";
        return;
      }

      const date30FromToday = new Date();
      date30FromToday.setDate(date30FromToday.getDate() - 30);
      const date30FromTodayStr = date30FromToday.toISOString().substring(0, 10);

      if (date1 === date30FromTodayStr) {
        this.dataSelectValue = "30 days";
        return;
      }

      this.dataSelectValue = "arbitrary date";
    },
    clearDates() {
      this.date = [];
    },
    format(dates) {
      const start_date = new Date(dates[0])?.toISOString().slice(0, -14) || "";
      const end_date = new Date(dates[1])?.toISOString().slice(0, -14) || "";
      return `${start_date} - ${end_date}`;
    },
    async getStatuses() {
      this.statuses = await getOrderStatuses();
    },
    async getOrders() {
      try {
        this.$emit("set_loading", true);
        const fields = ["order_status_id"];

        const request = { ...this.filter };

        fields.forEach((field) => {
          if (!request[field]) {
            delete request[field];
          }
        });
        const {
          orders,
          total,
          page_size = this.filter.page_size,
        } = await getOrders(request);
        this.orders = orders;
        this.totalPages = Math.ceil(total / page_size);
        this.$router.push({ name: "orders", query: this.filter });
      } catch (e) {
        const errorText = errorHandler(e, "Unrecognized error getting list of orders");
        this.$notify({ text: errorText, group: "error" });
      } finally {
        this.$emit("set_loading", false);
      }
    },
    orderHasPersonaliztion(order) {
      const { products } = order;
      return products.some((product) => !!product.personalization_text);
    },
    async setShowingDetailsOrder(order_id) {
      this.$emit("set_loading", true);
      this.showingOrder = await getOrderById(order_id);
      this.showDetails = true;
      this.$emit("set_loading", false);
    },
    makeSlug(title) {
      let url = title.replace(/^\s+|\s+$/g, "").toLowerCase();

      // remove accents, swap ñ for n, etc
      const from =
        "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
      const to =
        "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";

      for (var i = 0, l = from.length; i < l; i++) {
        url = url.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      url = url
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
.description_wrapper {
  width: 300px;
  padding: 2px 5px;
  white-space: initial;
  &_hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
</style>
