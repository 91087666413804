<template>
  <v-dialog :model-value="open" persistent>
    <v-card>
      <v-card-title class="text-h5"> Rename shop </v-card-title>
      <v-card-item>
        <v-select
          v-model="status"
          :items="$options.requestStatusesForSet"
          clearable
          prepend-icon="mdi-list-status"
          density="compact"
          color="light-blue"
          hide-details
        />
      </v-card-item>
      <v-card-item>
        <v-textarea label="Reason" v-model="comment"></v-textarea>
      </v-card-item>
      <v-card-actions>
        <v-btn
          color="green-darken-1"
          variant="text"
          :disabled="!status"
          @click="send"
        >
          Change status
        </v-btn>
        <v-btn color="red-darken-1" variant="text" @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RenameShopModal",
  requestStatusesForSet: ["accepted", "declined"],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: "",
      comment: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    send() {
      this.$emit("send", {
          status: this.status,
          comment: this.comment
      });
    },
  },
};
</script>
