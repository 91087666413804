import axios from 'axios'

export const getOrders = async (params) => {
    const query = new URLSearchParams(params).toString();
    console.log('query: ', query)
    const { data } = await axios.get(`/orders?${query}`);
    return data;
}

export const getOrderById = async (id) => {
    const { data } = await axios.get(`/orders/${id}`);
    return data;
}

export const getOrderStatuses = async () => {
    const { data } = await axios.get('/orders/status-list/');
    return data;
}

export const declineOrder = async (order_id, reason) => {
    const { data } = await axios.post(`/orders/${order_id}/decline`, { reason });
    return data;
}
