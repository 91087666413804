<template>
  <v-card title="Shops" class="mb-6">
    <v-card-subtitle>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="filter.shop_name"
            clearable
            label="Shop name"
            prepend-icon="mdi-store"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="filter.seller_last_name"
            clearable
            label="Owner last name"
            prepend-icon="mdi-account"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="filter.seller_email"
            clearable
            label="Owner email"
            prepend-icon="mdi-mail"
            density="compact"
            color="light-blue"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-checkbox
            :model-value="filter.weekend"
            :indeterminate="
              filter.weekend === null || filter.weekend === undefined
            "
            label="On weekend"
            @update:model-value="onChangeCheckbox('weekend')"
          ></v-checkbox>
        </v-col>
        <v-col cols="4">
          <v-checkbox
            :model-value="filter.active"
            :indeterminate="
              filter.active === null || filter.active === undefined
            "
            label="Active"
            @update:model-value="onChangeCheckbox('active')"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center align-cente py-4">
            <v-pagination
              v-model="filter.page"
              :length="totalPages"
              :total-visible="7"
              size="x-small"
              rounded="0"
              style="display: inline-block"
            ></v-pagination>
            <v-select
              v-model="filter.page_size"
              :items="$options.pageCounts"
              density="compact"
              color="light-blue"
              hide-details
              style="display: inline-block; max-width: 70px"
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>

    <!-- <RingLoader :loading="true" color="#03A9F4" :size="20" /> -->

    <v-table v-if="shops.length || is_loading" style="padding: 20px 0">
      <thead>
        <tr>
          <th class="text-left">Shop name</th>
          <th class="text-center">Active status</th>
          <th class="text-center">Ban status</th>
          <th class="text-left">Owner</th>
          <th class="text-center">On weekend</th>
          <th class="text-center">Total products</th>
          <th class="text-center">Active products</th>
          <th class="text-left sorted" @click="setSort('created_at')">
            Created at
            <span
              class="arrow-wrapper"
              :class="{
                asc: filter.sort_order === 'asc',
              }"
            >
              <v-icon
                :style="{
                  opacity: filter.sort_field === 'created_at' ? 1 : 0,
                }"
                class="arrow"
                icon="mdi-arrow-down"
                color="#fff"
              ></v-icon>
            </span>
          </th>
          <th class="text-left sorted" @click="setSort('updated_at')">
            Updated at
            <span
              class="arrow-wrapper"
              :class="{
                asc: filter.sort_order === 'asc',
              }"
            >
              <v-icon
                :style="{
                  opacity: filter.sort_field === 'updated_at' ? 1 : 0,
                }"
                class="arrow"
                icon="mdi-arrow-down"
                color="#fff"
              ></v-icon>
            </span>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in shops" :key="item.id">
          <td>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span style="margin-right: 20px">
                <a
                  :href="`${client_url}/author/${item.name.toLowerCase()}`"
                  target="_blank"
                  >{{ item.name }}</a
                >
              </span>
              <v-img
                v-if="item.logo?.url[200]"
                style="margin: 0; max-width: 50px; flex: none"
                width="50"
                height="50"
                :aspect-ratio="1"
                :src="item.logo?.url[200]"
              ></v-img>
            </div>
          </td>
          <td class="text-center">
            <v-icon
              v-if="item.active"
              icon="mdi-checkbox-marked-circle"
              size="large"
              color="green"
            ></v-icon>
            <v-icon
              v-else
              icon="mdi-close-circle"
              size="large"
              color="red"
            ></v-icon>
          </td>
          <td class="text-center">
            <v-icon
              v-if="item.admin_ban"
              icon="mdi-close-circle"
              size="large"
              color="red"
            ></v-icon>
            <!-- <span v-else>-</span> -->
          </td>
          <td>{{ item.user.full_name }} ({{ item.user.email }})</td>
          <td class="text-center">
            <v-icon
              v-if="item.weekend"
              icon="mdi-checkbox-marked-circle"
              size="large"
              color="green"
            ></v-icon>
            <v-icon
              v-else
              icon="mdi-close-circle"
              size="large"
              color="red"
            ></v-icon>
          </td>
          <td class="text-center">{{ item.total_products }}</td>
          <td class="text-center">{{ item.total_active_products }}</td>
          <td>
            {{ item.created_at?.replaceAll("T", " ") || "-" }}
          </td>
          <td>{{ item.updated_at?.replaceAll("T", " ") || "-" }}</td>
          <td>
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn color="indigo" style="margin: 5px 0" v-bind="props">
                  <v-icon icon="mdi-dots-vertical" size="large"></v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="goToShopOrders(item.name)">
                  <v-list-item-title>Go to orders</v-list-item-title>
                </v-list-item>
                <v-list-item @click="goToShopProducts(item.name)">
                  <v-list-item-title>Go to products</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="!item.admin_ban" @click="openBan(item.id)">
                  <v-list-item-title>Ban</v-list-item-title>
                </v-list-item>
                <v-list-item v-else @click="openBan(item.id)">
                  <v-list-item-title>Unban</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openBanHistoryModal(item.id)">
                  <v-list-item-title>Ban history </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- <v-btn
              color="primary"
              small
              style="font-size: 10px; padding: 7px; width: 100%; margin-top: 10px;"
              @click="goToShopOrders(item.name)"
            >
              Go to orders
            </v-btn>
            <v-btn
              color="primary"
              small
              style="font-size: 10px; padding: 7px; width: 100%; margin-top: 10px;"
              @click="goToShopProducts(item.name)"
            >
              Go to products
            </v-btn>
            <v-btn
              v-if="!item.admin_ban"
              color="red"
              small
              style="font-size: 10px; padding: 7px; width: 100%; margin-top: 10px; margin-bottom: 10px;"
              @click="openBan(item.id)"
            >
              Ban
            </v-btn>
            <v-btn
              v-else
              color="green"
              small
              style="font-size: 10px; padding: 7px; width: 100%; margin-top: 10px; margin-bottom: 10px;"
              @click="openBan(item.id)"
            >
              Unban
            </v-btn>
            <v-btn
              color="indigo"
              small
              style="font-size: 10px; padding: 7px; width: 100%; margin: 0 0 10px 0;"
              @click="openBanHistoryModal(item.id)"
            >
              Ban history 
            </v-btn> -->
          </td>
        </tr>
      </tbody>
    </v-table>
    <div v-else class="text-center">No entries to show</div>
    <div class="d-flex justify-center align-cente py-4">
      <v-pagination
        v-model="filter.page"
        :length="totalPages"
        :total-visible="7"
        size="x-small"
        rounded="0"
        style="display: inline-block"
      ></v-pagination>
      <v-select
        v-model="filter.page_size"
        :items="$options.pageCounts"
        density="compact"
        color="light-blue"
        hide-details
        style="display: inline-block; max-width: 70px"
      ></v-select>
    </div>
    <BanModal :open="showBanPopUp" @close="closeBan" @send="banShop" />
    <HistoryModal
      :open="showHistoryPopUp"
      :history="history"
      @close="closeBanHistoryModal"
    />
  </v-card>
</template>

<script>
import { getShops, banShop, getShopBanHistory } from "../services/shop";
import { debounce } from "lodash";
import BanModal from "../components/BanModal.vue";
import HistoryModal from "../components/HistoryModal.vue";
import errorHandler from "../helpers/errorHandler";

export default {
  statuses: [
    { text: "All", value: null },
    { text: "Active", value: true },
    { text: "Close", value: false },
  ],
  pageCounts: [10, 20, 50, 80, 100],
  components: { BanModal, HistoryModal },
  props: {
    is_loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const statusOptions = {
      true: true,
      false: false,
    };
    const query = {
      ...this.$route.query,
      page: +this.$route.query.page || 1,
      page_size: +this.$route.query.page_size || 10,
      sort_order: this.$route.query.sort_order || "asc",
      sort_field: this.$route.query.sort_field || "created_at",
      weekend: statusOptions[this.$route.query.weekend] || null,
    };
    return {
      shops: [],
      filter: { ...query },
      oldFilter: { ...query },
      debounce_fun: null,
      client_url: process.env.VUE_APP_CLIENT_URL,
      totalPages: 1,
      showBanPopUp: false,
      showHistoryPopUp: false,
      bannedShopId: null,
      history: [],
    };
  },

  async created() {
    this.debounce_fun = debounce(() => {
      this.getShops();
    }, 1500);
    this.getShops();
  },

  watch: {
    filter: {
      deep: true,
      async handler(newFilter) {
        const fields = [
          "seller_name",
          "shop_name",
          "seller_email",
          "seller_last_name",
          "seller_middle_name",
        ];
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          if (newFilter[field] !== this.oldFilter[field]) {
            this.oldFilter = { ...newFilter };
            if (newFilter[field] === null) {
              this.filter[field] = "";
            }
            this.debounce_fun();
            return;
          }
        }

        this.oldFilter = { ...newFilter };
        this.getShops();
      },
    },
  },

  methods: {
    closeBanHistoryModal() {
      this.showHistoryPopUp = false;
      this.history = [];
    },
    async openBanHistoryModal(shop_id) {
      await this.getBanHistory(shop_id);
      this.showHistoryPopUp = true;
    },
    async getBanHistory(shop_id) {
      try {
        this.$emit("set_loading", true);
        this.history = await getShopBanHistory(shop_id);
      } catch (e) {
        const errorText = errorHandler(
          e,
          "Unrecognized error getting ban history"
        );
        this.$notify({ text: errorText, group: "error" });
        throw e;
      } finally {
        this.$emit("set_loading", false);
      }
    },
    openBan(shop_id) {
      this.bannedShopId = shop_id;
      this.showBanPopUp = true;
    },
    closeBan() {
      this.bannedShopId = null;
      this.showBanPopUp = false;
    },
    async banShop(comment) {
      try {
        this.$emit("set_loading", true);
        const shop = this.shops.find((shop) => shop.id === this.bannedShopId);
        await banShop(this.bannedShopId, comment, !shop.admin_ban);
        this.closeBan();
        shop.admin_ban = !shop.admin_ban;
      } catch (e) {
        const errorText = errorHandler(e, "Unidentified store ban error");
        this.$notify({ text: errorText, group: "error" });
      } finally {
        this.$emit("set_loading", false);
      }
    },
    goToShopProducts(shop_name) {
      this.$router.push({
        name: "products",
        query: {
          shop_name,
        },
      });
    },
    goToShopOrders(shop_name) {
      this.$router.push({
        name: "orders",
        query: {
          shop_name,
        },
      });
    },
    async getShops() {
      try {
        this.$emit("set_loading", true);

        const fields = [
          "seller_name",
          "shop_name",
          "seller_email",
          "seller_last_name",
          "seller_middle_name",
          "weekend",
          "active",
        ];

        const request = { ...this.filter };

        fields.forEach((field) => {
          if (request[field] === null) {
            delete request[field];
          }
        });

        const { shops, total, page_size } = await getShops(request);

        this.totalPages = Math.ceil(total / page_size);
        this.shops = shops;
        this.$router.push({ name: "shops", query: this.filter });
      } catch (e) {
        const errorText = errorHandler(e, "Unrecognized error getting store list");
        this.$notify({ text: errorText, group: "error" });
      } finally {
        this.$emit("set_loading", false);
      }
    },
    setSort(sort_field) {
      if (this.filter.sort_field === sort_field) {
        this.filter.sort_order =
          this.filter.sort_order === "asc" ? "desc" : "asc";
        return;
      }
      this.filter.sort_field = sort_field;
      this.sort_order = "asc";
    },

    onChangeCheckbox(key) {
      if (this.filter[key] === null || this.filter[key] === undefined) {
        this.filter[key] = true;
        return;
      }
      if (this.filter[key] === true) {
        this.filter[key] = false;
        return;
      }
      if (this.filter[key] === false) {
        this.filter[key] = null;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
th.sorted {
  cursor: pointer;
  &:hover .arrow {
    opacity: 0.5 !important;
    display: inline-block !important;
    transform: rotate(180deg);
  }
}

.arrow-wrapper {
  display: inline-block;
  &.asc {
    transform: rotate(180deg);
  }
}
</style>
