<template>
  <v-app :theme="theme">
    <v-btn class="theme-btn" @click.prevent="changeTeam">
      <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>
    <v-bottom-navigation v-if="$route.name !== 'login'" v-model="page_name">
      <v-btn value="shops">
        <v-icon>mdi-store</v-icon>

        Shops
      </v-btn>

      <v-btn value="products">
        <v-icon>mdi-archive</v-icon>

        Products
      </v-btn>

      <v-btn value="orders">
        <v-icon>mdi-cash-sync</v-icon>

        Orders
      </v-btn>

      <v-btn value="users">
        <v-icon>mdi-account-group</v-icon>

        Users
      </v-btn>

      <v-btn value="sellers">
        <v-icon>mdi-account-tie</v-icon>

        Sellers
      </v-btn>

      <v-btn value="rename_requests">
        <v-icon>mdi-rename</v-icon>

        Rename Requests
      </v-btn>

      <v-btn value="logout">
        <v-icon color="red">mdi-logout</v-icon>

        Log out
      </v-btn>
    </v-bottom-navigation>

    <v-main>
      <v-container>
        <router-view :is_loading="is_loading" @set_loading="set_loading" />
        <loading
          :active="is_loading"
          :is-full-page="true"
          loader="bars"
          color="#03A9F4"
          background-color="rgba(0, 0, 0, 0)"
          width="260"
          height="260"
        ></loading>
        <notifications group="error" class="error" />
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent>
            <v-card>
              <v-card-title class="text-h5"> Logout </v-card-title>
              <v-card-text
                >Are you sure you want to log out of your profile?</v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="dialog = false"
                >
                  No
                </v-btn>
                <v-btn color="red-darken-1" variant="text" @click="logout">
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { logout } from "./services/auth";
import Loading from "vue3-loading-overlay";

export default {
  components: { Loading },
  data() {
    return {
      theme: localStorage.getItem("theme") || "light",
      page_name: "",
      dialog: false,
      is_loading: false,
    };
  },
  watch: {
    "$route.name": function () {
      this.page_name = this.$route.name;
    },
    dialog() {
      if (!this.dialog) {
        this.page_name = this.$route.name;
      }
    },
    page_name() {
      if (this.page_name === "logout") {
        this.dialog = true;
        return;
      }

      this.$router.push(this.page_name);
    },
    theme() {
      localStorage.setItem("theme", this.theme);
    },
  },
  methods: {
    async logout() {
      this.set_loading(true);
      this.dialog = false;
      await logout();
      this.$router.push({
        name: "login",
      });
      this.set_loading(false);
    },
    set_loading(val) {
      this.is_loading = val;
    },
    changeTeam() {
      this.theme = this.theme === "light" ? "dark" : "light";
    },
  },
};

// import { ref } from "vue";

// const theme = ref("dark");

// function onClick () {
//   theme.value = theme.value === 'light' ? 'dark' : 'light'
// }
</script>

<style lang="scss">
.v-theme--light {
  .v-btn__overlay,
  .v-chip.v-chip--density-default .v-chip__underlay {
    background: rgba(#fff, 0.5) !important;
    border: 1px #000 solid;
  }

  .v-card,
  .v-bottom-navigation {
    background: #fff !important;
  }
}

.v-theme--dark {
  .dp__input_wrap {
    input {
      --dp-background-color: #292929;
      --dp-text-color: #fff;
      border: none;
    }
  }
}

.v-card-subtitle {
  opacity: 1 !important;
}

.v-card {
  overflow: visible !important;
}

div[data-test="open-time-picker-btn"],
.dp__selection_preview {
  display: none;
}

.theme-btn {
  position: fixed !important;
  top: 0;
  left: 0;
}

.vue-notification-group.error {
  .vue-notification {
    margin: 0 5px 5px;
    padding: 10px;
    font-size: 12px;
    color: #ffffff;
    background: #e54d42 !important;
    border-left-color: #b82e24 !important;
  }
}
</style>
