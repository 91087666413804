import { createApp } from 'vue'
import { pinia } from './stores'
import App from './App.vue'
import Notifications from '@kyvg/vue3-notification'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import './api/axios'
import { router } from './router'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
  },
})

createApp(App)
  .use(pinia)
  .use(vuetify)
  .use(router)
  .use(Notifications)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app')
