import Login from '../pages/LoginPage'
import Shops from '../pages/ShopsPage'
import Products from '../pages/ProductsPage'
import Orders from '../pages/OrdersPages'
import Users from '../pages/UsersPage'
import Seller from '../pages/SellerPage'
import RenameRequestsPage from '../pages/RenameRequestsPage'

export const routes = [
    { 
        path: '/',
        redirect: { name: 'login' }
    },
    { 
        path: '/login',
        component: Login,
        name: 'login'
    },
    {
        path: '/shops',
        component: Shops,
        name: 'shops'
    },
    {
        path: '/products',
        component: Products,
        name: 'products'
    },
    {
        path: '/orders',
        component: Orders,
        name: 'orders'
    },
    {
        path: '/users',
        component: Users,
        name: 'users'
    },
    {
        path: '/sellers',
        component: Seller,
        name: 'sellers'
    },
    {
        path: '/rename_requests',
        component: RenameRequestsPage,
        name: 'rename_requests'
    },
]