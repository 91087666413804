import axios from 'axios'
import { router } from '../router'

axios.interceptors.response.use(config => config, (error) => {
    const { status = null } = error?.response || {}
    if(status === 401) {
        router.push({
            name: 'login'
        })
    }
    throw error;
})

axios.defaults.baseURL = '/admin-api';
axios.defaults.withCredentials = true
