import axios from 'axios'

export const getProducts = async (params) => {
    const query = new URLSearchParams(params).toString();
    const { data } = await axios.get(`/products?${query}`);
    return data;
}

export const banProduct = async (product_id, comment, active) => {
    const { data } = await axios.patch(`/products/${product_id}/ban`, { comment, active });
    return data;
}

export const getProductBanHistory = async (shop_id) => {
    const { data } = await axios.get(`/products/${shop_id}/ban-history`);
    return data;
} 
