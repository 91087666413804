import axios from 'axios'

export const getRequests = async (params = {}) => {
    const query = new URLSearchParams(params).toString();
    const { data } = await axios.get(`/shops/rename-requests?${query}`);
    return data;
}

export const changeRenameStatus = async (status, rename_shop_request_id, comment) => {
    const { data } = await axios.patch('/shops/rename-request/status', { status, rename_shop_request_id, comment });
    return data;
}
