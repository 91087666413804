import axios from 'axios'

export const getUsers = async (params = {}) => {
    const query = new URLSearchParams(params).toString();
    const { data } = await axios.get(`/users?${query}&role_code=customer`);
    return data;
}

export const banUser = async (user_id, comment, active) => {
    const { data } = await axios.patch(`/users/${user_id}/ban`, { comment, active });
    return data;
}

export const getUserBanHistory = async (user_id) => {
    const { data } = await axios.get(`/users/${user_id}/ban-history`);
    return data;
} 
