<template>
  <v-row>
    <v-col cols="4"></v-col>
    <v-col cols="4">
      <v-card class="mx-auto" style="max-width: 500px">
        <v-form ref="form" v-model="form" class="pa-4 pt-6">
          <v-text-field
            v-model="email"
            :rules="[rules.email]"
            filled
            color="light-blue"
            label="Email address"
            type="email"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="[rules.required, rules.length(6)]"
            filled
            color="light-blue"
            counter="6"
            label="Password"
            style="min-height: 96px"
            type="password"
          ></v-text-field>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="$refs.form.reset()"> Clear </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!form"
            :loading="isLoading"
            class="white--text"
            color="light-blue accent-4"
            depressed
            @click="login"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="4"></v-col>
  </v-row>
</template>

<script>
import { login } from "../services/auth";
import errorHandler from "../helpers/errorHandler"

export default {
  data: () => ({
    agreement: false,
    bio: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts",
    email: undefined,
    form: false,
    isLoading: false,
    password: undefined,
    phone: undefined,
    rules: {
      email: (v) => !!(v || "").match(/@/) || "Please enter a valid email",
      length: (len) => (v) =>
        (v || "").length >= len || `Invalid character length, required ${len}`,
      required: (v) => !!v || "This field is required",
    },
  }),
  methods: {
    async login() {
      try {
        await login(this.email, this.password);
        this.$router.push({
          name: "shops",
        });
      } catch (e) {
        const errorText = errorHandler(e, "Unrecognized authorization error");
        this.$notify({ text: errorText, group: "error" });
      }
    },
  },
};
</script>